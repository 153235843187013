@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import '../../color/index.scss';
@import '../../mixin';

.checkin {
  font-family: 'Spoqa Han Sans';
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 70px;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: linear-gradient(-45deg, #40c057, #49bbc4);
  z-index: 10;
  height: 100vh;
  overflow-y: auto;

  .text {
    color: #fff;
    flex: 1;
    height: auto;
    margin-bottom: 80px;
    font-size: 48px;
    font-weight: 700;
    letter-spacing: -1px;
    transition: font-size 0.35s;
  }

  .top-logo_image {
    width: 140px;
  }

  .branch-name {
    position: relative;
    font-size: 38px;
    margin-left: 25px;
    bottom: 10px;
  }

  .center-text {
    line-height: 1.6;
    letter-spacing: -2px;
  }

  .number {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-30px);
    h4 {
      font-size: 20px;
      line-height: 24px;
    }

    .number_text {
      width: 100%;
      height: 75px;
      font-size: 48px;
      font-weight: 700;
      color: #fff;
      text-align: center;
      margin: 0 auto 25px;
      border-bottom: 4px solid #fff;
    }

    .number_pad {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 440px;
      transition: 0.35s;
      .pad {
        width: 120px;
        height: 120px;
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        color: $primary-color;
        background: #d5f2da;
        border: 1px solid #d5f2da;
        border-radius: 50%;
        margin-bottom: 25px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.03);
        transition: background 0.35s, color 0.35s, border 0.35s, box-shadow 0.35s;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: 0.35s;
      }

      .pad:hover {
        background: $primary-color;
        color: #fff;
        border: 1px solid $primary-color;
        box-shadow: 0 0px 8px rgba(0, 0, 0, 0.3);
      }
    }

    .number_checkin {
      width: 100%;
      height: 80px;
      height: 80px;
      margin: 0 auto;
      color: #ddd;
      border-radius: 6px;
      border: 1px solid $line-color;
      // transition: 0.35s;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.04);
      background: #f1f1f1;
      cursor: pointer;
      font-size: 34px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .number_checkin_ok {
      color: #495057;
      background: #d5f2da;
    }
  }

  .top {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 54px;
    margin-top: 100px;
    background: url('/images/checkin_top_logo.png') 80px / contain no-repeat;

    span {
      color: #fff;
      font-size: 50px;
      font-weight: 700;
      margin-left: 250px;
    }
  }
  .plateCheckWrapper {
    width: 30px;
    height: 30px;
    background: #f9f9f9;
    color: #dddddd;
    border: 2px solid #dddddd;
    border-radius: 50%;
    margin-top: 30px;
    margin-right: 20px;
    .plateCheckButton {
      position: relative;
      font-size: 42px;
      font-weight: 500;
      margin-left: 8px;
      bottom: 30px;
    }
  }
  .footer {
    width: 100%;
    color: #fff;
    font-size: 28px;
    font-weight: 600;

    .qr-container {
      display: flex;
      text-align: center;
    }

    .footer-title {
      margin-bottom: 20px;
    }

    .qr-image {
      width: 200px;
      transition: 0.35s;
    }

    .qr-text {
      position: relative;
      font-size: 24px;
    }

    .vertical-line {
      width: 1px;
      height: 200px;
      margin: 0 20px;
      text-align: center;
      background-color: #f9f9f9;
    }
  }
}

@include lg {
  .checkin {
    .text {
      font-size: 40px;
    }
    .branch-name {
      font-size: 32px;
    }
    .top-logo_image {
      width: 120px;
    }
    .number {
      transform: translateY(-30px);
      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      .number_text {
        height: 75px;
        font-size: 48px;
        margin: 0 auto 25px;
        border-bottom: 3px solid #fff;
      }
      .number_pad {
        width: 360px;

        .pad {
          width: 100px;
          height: 100px;
          font-size: 36px;
          margin-bottom: 20px;
        }
      }

      .number_checkin {
        font-size: 30px;
      }
    }
    .footer {
      font-size: 22px;

      .qr-image {
        width: 160px;
      }

      .qr-text {
        font-size: 18px;
      }
      .vertical-line {
        height: 160px;
      }
    }
  }
}

@include md {
  .checkin {
    padding: 30px;
    .text {
      font-size: 28px;
    }
    .branch-name {
      font-size: 26px;
    }
    .top-logo_image {
      width: 100px;
    }
    .number {
      transform: translateY(-30px);
      h4 {
        font-size: 16px;
      }

      .number_text {
        height: 60px;
        font-size: 32px;
        margin: 0 auto 20px;
        border-bottom: 2px solid #fff;
      }
      .number_pad {
        width: 300px;
        .pad {
          width: 80px;
          height: 80px;
          font-size: 28px;
        }
      }

      .number_checkin {
        font-size: 24px;
        height: 70px;
      }
    }
    .footer {
      font-size: 18px;

      .qr-image {
        width: 120px;
      }

      .qr-text {
        font-size: 16px;
      }
      .vertical-line {
        height: 120px;
      }
    }
  }
}

@include sm {
  .checkin {
    display: block;
    padding: 30px 20px;

    .text {
      margin-left: 0;
      text-align: center;
      margin-bottom: 30px;
    }

    .branch-name {
      margin-left: 10px;
    }

    .footer {
      text-align: center;
      .qr-container {
        justify-content: center;
        margin-bottom: 20px;
      }
    }

    .number {
      width: 220px;
      transform: none;
      margin: 0 auto;

      .number_text {
        height: 50px;
        font-size: 28px;
      }
      .number_pad {
        width: 220px;
        .pad {
          width: 60px;
          height: 60px;
          font-size: 28px;
          margin-bottom: 15px;
        }
      }

      .number_checkin {
        font-size: 20px;
        height: 50px;
      }
    }
    .footer {
      font-size: 18px;

      .qr-image {
        width: 80px;
      }

      .vertical-line {
        height: 80px;
      }
    }
  }

  /* 가로버전 sm */
  @media all and (orientation: landscape) {
    .checkin {
      .number {
        width: 410px;
        .number_pad {
          width: 410px;
        }
      }
      .btn_del {
        order: 10;
      }
      .btn_clear {
        order: 11;
      }
    }
  }
}

@media all and (max-width: 500px) {
  .checkin {
    padding: 30px 15px;
    .text {
      font-size: 20px;
    }
    .branch-name {
      font-size: 20px;
      margin-left: 0;
    }
    .top-logo_image {
      width: 100px;
      display: block;
      margin: 0 auto 20px;
    }
  }
}

// 색상 설정
$white-color: white !global;

$primary-color: #495057 !global;
$effect-color: #adb5bd !global;
$background-color: #f9f9f9 !global;
$line-color: #ddd !global;

$sub-color: #eaecef !global;
$green-color: #12b886 !global;
$subEffect-color: #ced4da !global;
$red-color: #fa5252 !global;
$blue-color: #657bf2 !global;

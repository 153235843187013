$breakpoint-xs: 360px;
$breakpoint-sm: 768px;
$breakpoint-md: 1024px;
$breakpoint-lg: 1280px;

/* 좌우 padding */
$horizontalGlobalPadding-sm: 15px;
$horizontalGlobalPadding-md: 20px;
$horizontalGlobalPadding-lg: 30px;
$horizontalGlobalPadding-xl: 50px;

/* 컨텐츠 영역 박스 패딩 */
$contentBodyPadding-sm: 15px $horizontalGlobalPadding-sm 30px;
$contentBodyPadding-md: 20px $horizontalGlobalPadding-md 30px;
$contentBodyPadding-lg: 30px $horizontalGlobalPadding-lg 40px;
$contentBodyPadding-xl: 30px $horizontalGlobalPadding-xl 40px;

// :export {
//   breakpointSm: $breakpoint-sm;
//   breakpointMd: $breakpoint-md;
//   breakpointLg: $breakpoint-lg;
// }

/************************************
  반응형 break point
  * xs - Mobile ( 360px 이하. 디자인 세부 조정 시에만 사용)
  * sm - Mobile ( 768px 이하)
  * md - Tablet ( 769px ~ 1024px)
  * lg - PC ( 1025px ~ 1280px)
  * xl - PC ( 1281px 이상)
*************************************/

@mixin xs {
  @media (max-width: #{$breakpoint-xs}) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-sm}) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md}) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$breakpoint-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$breakpoint-lg + 1}) {
    @content;
  }
}

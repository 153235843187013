@import '../../color/index.scss';
@import '../../mixin';

.plate_panel_plus {
  width: 280px;
  padding: 12px;
  background: $white-color;
  border: 1px solid $line-color;
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
  transition: 0.35s;
  margin: 0px 8px 16px;
  flex-grow: 1;

  @include sm {
    width: 100%;
    margin: 0px 0px 16px;
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    button {
      margin-top: 8px;
    }
  }

  .number {
    font-size: 32px;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 14px;
      font-weight: regular;
    }
  }

  .waiting {
    color: $effect-color;
  }

  .time {
    width: 100%;
    margin-top: 4px;
    margin-bottom: 4px;
  }
}
.plate_panel_use {
  background: $green-color;
  border: 1px solid $green-color;
  box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);
  color: $white-color;
}

.locker_panel:hover {
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.16);
  transition: 0.35s;
  background: darken($white-color, 2%);
}

.lokcer_panel_use {
  background: $green-color;
  color: $white-color;
  border: 1px solid $green-color;
  box-shadow: 0 3px 6px rgba(18, 184, 134, 0.3);
}

.lokcer_panel_use:hover {
  background: $green-color;
  color: white;
}

@import './mixin';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

// 공통

h3 {
  margin: 0;
  padding: 0;
  font-size: 32px;
}
h4 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}
h5 {
  margin: 0;
  padding: 0;
  font-size: 16px;
}
h6 {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

hr {
  height: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.wrap {
  padding: 0 $horizontalGlobalPadding-xl;
  box-sizing: border-box;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  display: flex;
  @include lg {
    padding: 0 $horizontalGlobalPadding-lg;
  }

  @include md {
    padding: 0 $horizontalGlobalPadding-md;
  }
  @include sm {
    padding: 0 $horizontalGlobalPadding-sm;
  }
}

.container {
  padding: 0 50px;
  // min-width: 768px;
  width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* CacheRoute 를 사용하는 Router의 div 영역 조정 */
.cache-route-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* pagination */
.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  cursor: pointer;
  margin: 20px auto;

  @include sm {
    margin: 10px auto;
  }

  .previous {
    margin-right: 4px;
  }

  .next {
    margin-left: 4px;
  }
}

.pagination a {
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #707070;
  /*#6c7ac9;*/
  color: #414141;
  /*#F49314;*/
  /*#6c7ac9;*/
  @include sm {
    padding: 6px 10px;
  }
}

.pagination__link {
  font-weight: bold;
}

.pagination__link--active a {
  color: #707070;
  /*#fff;*/
  background: #f49314;
  /*#6c7ac9;*/
}

.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

/* webcam container */
.web-cam-box {
  width: 100%;
  text-align: center;

  video {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 8px;
  }

  .web-cam-controls {
    display: flex;
    justify-content: space-between;
  }
}

.chartjs-render-monitor {
  // border: 2px solid yellow;
}
.chart_bar {
  border: 1px solid red;
  max-width: 100%;
}

/* bar, line 차트를 위한 반응형 컨테이너(maintainAspectRatio: false, 차트 옵션을 함께 줄 것) */
.rect_chart_response {
  position: relative;
  height: 300px;
  width: 100%;
  overflow: hidden;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

/* react-tabs 디자인 개선 */
.react-tabs {
  @include sm {
    .react-tabs__tab-list {
    }
    .react-tabs__tab {
      padding: 0px 3px 8px;
      border: none;

      border-radius: 5px;
      // & + .react-tabs__tab {
      //   margin-left: 5px;
      // }
    }
    .react-tabs__tab--selected {
      border: none;
      color: #4b96e6;
      background: transparent;
    }
  }
}

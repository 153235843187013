.go_grid_row_cur_time {
    /*background-color: #F49314 !important;*/
    /*color: #F49314 !important*/
    background: #d3f9d8 !important;
    border: none !important;
    border-top: 2px solid #12b886 !important;
    border-bottom: 2px solid #12b886 !important;
    /*color: #12b886 !important;*/
    box-sizing: border-box !important;
}
.go_grid_cell_absent {
    /*background-color: #F49314 !important;*/
    /*color: #F49314 !important*/
    color: #aa083d !important;
    font-weight: bold !important;
}

.go_grid_cell_attend  {
    color: #12b886 !important;
    font-weight: bold !important;
}

.go_grid_cell_reserved  {
    font-weight: normal  !important;
}
